<template>
  <FormKit type="form" :id="formId" :actions="false" @submit="handleSubmit">
    <div class="space-y-4">
      <FormKit
        type="text"
        name="name"
        :label="t('label.name')"
        :value="prize?.name"
        validation="required"
      />

      <FormKit
        type="number"
        name="quantity"
        :label="t('label.quantity')"
        :value="prize?.quantity ?? 1"
        validation="required"
      />

      <FormKit
        type="wysiwyg"
        name="description"
        :label="t('label.description')"
        :value="prize?.description ?? ''"
        validation="required"
      />

      <FormKit
        type="text"
        name="concessionId"
        :label="t('label.concessionId')"
        :value="prize?.concession?.id ?? ''"
        validation="required"
        :help="t('help.concessionId')"
      />

      <FormKit
        v-if="raffle?.dailyRaffle"
        type="select"
        name="day"
        :label="t('label.day')"
        :options="daysOptions"
        :placeholder="t('placeholder.day')"
        :value="prize?.day"
        :help="t('help.day')"
      />

      <FormKit
        type="checkbox"
        name="active"
        :value="prize?.active"
        :label="t('label.active')"
      />

      <FormKit
        v-if="!!prize"
        type="checkbox"
        name="delete"
        :label="t('label.delete')"
      />

      <div class="grid grid-cols-2 gap-6">
        <KButton
          :theme="BUTTON_THEME.SECONDARY"
          :size="BUTTON_SIZE.SM"
          :text="t('btn.cancel')"
          @click="emit('close')"
        />

        <KButton
          :theme="BUTTON_THEME.PRIMARY"
          :size="BUTTON_SIZE.SM"
          :text="!!prize ? t('btn.update') : t('btn.add')"
          @click="submitForm(formId)"
        />
      </div>
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import { submitForm } from '@formkit/vue'
import type { RafflePrize, Raffle } from '#gql/default'
import { MESSAGE_SEVERITY } from '@base/constants/message'
import { BUTTON_THEME, BUTTON_SIZE } from '@kh-ui/constants/button'
import useMessage from '@base/composables/use-message'
import useRaffleAdmin from '@builder/composables/use-raffle-admin'

interface Props {
  raffle: Raffle
  prize?: RafflePrize
}

const { raffle, prize } = defineProps<Props>()

const { add: addMessage } = useMessage()
const { days, addOrUpdatePrize, deletePrize } = await useRaffleAdmin()

const emit = defineEmits(['close'])
const { t, d } = useI18n()
const formId = useId()

const daysOptions = computed(() =>
  days.value.map((day) => ({
    label: d(day, 'short'),
    value: day,
  })),
)

async function handleSubmit(formData: any) {
  try {
    let message = ''

    if (prize && formData.delete) {
      await deletePrize(prize.id)
      message = t('toast.success.delete', { id: prize.id })
    } else {
      const newPrize = await addOrUpdatePrize({
        ...formData,
        id: prize?.id,
        active: formData.active ?? false,
        raffleId: raffle.id,
        quantity: formData.quantity ?? 1,
      })

      message = !!prize
        ? t('toast.success.update', { id: newPrize.id })
        : t('toast.success.add', { id: newPrize.id })
    }

    await refreshNuxtData()
    emit('close')
  } catch (error) {
    addMessage({ message: error.message, severity: MESSAGE_SEVERITY.ERROR })
    emit('close')
  }
}

defineOptions({
  name: 'RafflePrizesForm',
})
</script>

<i18n>
de:
  placeholder:
    day: "Tag auswählen"
  label:
    delete: "Löschen"
    name: "Name"
    day: "Tag"
    active: "Aktiv"
    description: "Beschreibung"
    concessionId: "Concession ID"
    quantity: "Anzahl"
  help:
    day: "Ohne Tag sind Preise für die ganze Verlosung gültig"
    concessionId: "Nicht vorhandene Concessions führen zu einem Fehler beim speichern."
  btn:
    add: "Hinzufügen"
    update: "Aktualisieren"
    cancel: "Abbrechen"
  toast:
    success:
      add: "Preis {id} wurde erfolgreich hinzugefügt"
      update: "Preis {id} wurde erfolgreich aktualisiert"
      delete: "Preis {id} wurde erfolgreich gelöscht"
</i18n>
