<script setup lang="ts">
import type { Raffle } from '#gql/default'
import useModal from '@base/composables/use-modal'

interface Props {
  raffle?: Raffle
}

const { raffle } = defineProps<Props>()
const emit = defineEmits(['close'])

const { t } = useI18n()
const { icons } = useDesign()
const { close, open, cssClasses, cssStyles } = useModal({
  onClose: () => emit('close'),
})
open()

const pageTitle = computed(() =>
  !raffle ? t('page.title.add') : t('page.title.edit', { name: raffle.name }),
)

defineOptions({
  name: 'RaffleModal',
})
</script>

<template>
  <Teleport to="body">
    <div
      :class="cssClasses.container"
      :style="cssStyles.container"
      @click.self="close"
    >
      <div
        :class="cssClasses.modal"
        class="bg-light dark:bg-dark relative w-full max-w-xl rounded-lg p-0 shadow-xl"
      >
        <!-- Header -->
        <div
          class="border-neutral-light-3 dark:border-neutral-dark-1 relative mb-4 border-b p-2"
        >
          <h2 class="pr-8 text-2xl font-bold" v-text="pageTitle" />

          <ButtonClose :class="cssClasses.close" @click="close" />
        </div>

        <!-- Content -->
        <RaffleForm :raffle @close="close" />
      </div>
      <div :class="cssClasses.backdrop" @click.self="close" />
    </div>
  </Teleport>
</template>

<i18n>
de:
  page:
    title:
      add: "Gewinnspiel hinzufügen"
      edit: "{name} bearbeiten"
</i18n>
